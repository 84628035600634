var hostForAPICalls = location.hostname;
hostForAPICalls = (typeof process?.env?.VUE_APP_API_URL === 'undefined' || !process.env.VUE_APP_API_URL.includes('https')) 
? 'https://cert.consentic.com' : process.env.VUE_APP_API_URL;
const apiCall = require('axios').create({ withCredentials: true, 
  headers: { 'Cache-Control': 'no-cache' },
  baseURL: hostForAPICalls});
  
  const getDefaultState = () => {
    return {
      CRSig:'',
      consentRequest: {},
      consentTypes: [],
      consentPDF: '',
      bFetchingData: false,
    }
  }
  
  const state = getDefaultState();
  
  const getters = {
    getCRSig: (state) => { return state.CRSig },
    getConsentRequest: (state) => { return state.consentRequest },
    getConsentTypes: (state) => { return state.consentTypes },
    getConsentPDF: (state) => { return state.consentPDF },
  };
  
  const actions = {
    setSignature({commit},sig) {
      commit('setSig',sig);
    },
    // eslint-disable-next-line
    async fetchConsentRequest({ commit }, formData) {
      apiCall.get(hostForAPICalls + `/api/v1/p/consent-request/${formData.id}?signature=${formData.signature}`,
        {headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}})
      .then(response => {
        console.warn("ConsentRequest info:",response.data.data);
        commit('setConsentRequest', response.data.data);
        commit('setConsentTypes',response.data.types);
        state.bFetchingData=false;
      })
      .catch(error => {
        console.log("Error fetching patient:",error);
        commit('setConsentRequest', {'success':false,'time':new Date()});
        state.bFetchingData=false;
      });
    },

    // eslint-disable-next-line
    async sendCompletedConsentEmail({ commit, dispatch }, data) {
      var notificationForm = new FormData();
      notificationForm.set('email',data.email_recipient);
      apiCall.post(hostForAPICalls + `/api/v1/consent-requests/${data.id}/send-completed-consent-email`,notificationForm,
          {headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
      }).then(response => {
        if (response?.data?.success == false) {
          console.log("Error sending email:",response);
        }
        dispatch('alerts/Notify', { 'txtMsg': 'Email sent', 'txtType': 'positive' }, { root: true });
      })
      .catch(error => {
          console.log("Error sending email:",error);
          dispatch('alerts/Notify', { 'txtMsg': 'Error sending email', 'txtType': 'negative' }, { root: true });
      })
      .finally(() => {
          state.bFetchingData = false; // Ensure fetching state is updated
      });
    },

    // eslint-disable-next-line
    async updateConsentRequest({ commit }, formData) {      

      formData.question = JSON.stringify(formData?.questions ?? []);
      formData.signature = (formData.signature ? formData.signature : '');

      const form = {
        ...formData,
        '_method': 'put'
      };
      
      const response = await apiCall.post(`${hostForAPICalls}/api/v1/p/consent-request/${formData.id}?signature=${formData.signature}`, form,
        { headers: 
          { 
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data'
          } 
        }
      );

      if (response.status === 200) {
        // 
      } else {
        throw new Error(JSON.stringify(response.data));
      }
    },

    // eslint-disable-next-line
    async updateConsentRequestOptedOut({ commit }, formData) {      

      const form = {
        ...formData,
        '_method': 'put'
      };
      
      const response = await apiCall.post(`${hostForAPICalls}/api/v1/p/consent-request/${formData.id}/opted-out?signature=${formData.signature}`, form,
        { headers: 
          { 
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data'
          } 
        }
      );

      if (response.status === 200) {
        // 
      } else {
        throw new Error(JSON.stringify(response.data));
      }
    },

    async videoWatched({ state }, id) {      
      
      const response = await apiCall.post(`${hostForAPICalls}/api/v1/p/consent-request/${id}/videoWatched?signature=${state.CRSig}`,
        { headers: 
          {  'Authorization': `Bearer ${localStorage.getItem('token')}`,
             'Content-Type': 'multipart/form-data' } 
        }
      );

      if (response.status !== 200) { throw new Error(JSON.stringify(response.data)); }
    },

    async docRead({ state }, id) {      
      
      const response = await apiCall.post(`${hostForAPICalls}/api/v1/p/consent-request/${id}/docRead?signature=${state.CRSig}`,
        { headers: 
          {  'Authorization': `Bearer ${localStorage.getItem('token')}`,
             'Content-Type': 'multipart/form-data' } 
        }
      );

      if (response.status !== 200) { throw new Error(JSON.stringify(response.data)); }
    },

    // eslint-disable-next-line
    async fetchPDF({ commit }, filename) {
      apiCall.get(hostForAPICalls + `/api/v1/pdf/${filename}`,
        {
          responseType: 'blob',
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
        }
      ).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        commit('setConsentPDF', url);
        state.bFetchingData=false;
      })
      .catch(error => {
        console.log("Error fetching consent pdf:",error);
        state.bFetchingData=false;
      });
    },
    // eslint-disable-next-line
    async downloadAttachment({ commit }, attachment) {
      apiCall.get(hostForAPICalls + `/api/v1/download/${attachment.id}`,
        {
          responseType: 'blob',
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
        }
      ).then(response => {
        
        const blob = new Blob([response.data], { type: response.headers.get('Content-Type') });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = attachment.name;
        link.click();

        // Cleanup the temporary URL
        window.URL.revokeObjectURL(url);

        state.bFetchingData=false;
      })
      .catch(error => {
        console.log("Error fetching consent pdf:",error);
        state.bFetchingData=false;
      });
    },
    // eslint-disable-next-line
    async downloadPDF({ commit }, filename) {
      apiCall.get(hostForAPICalls + `/api/v1/download/pdf/${filename}`,
        {
          responseType: 'blob',
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
        }
      ).then(response => {
        
        const blob = new Blob([response.data], { type: response.headers.get('Content-Type') });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        link.click();

        // Cleanup the temporary URL
        window.URL.revokeObjectURL(url);

        state.bFetchingData=false;
      })
      .catch(error => {
        console.log("Error fetching consent pdf:",error);
        state.bFetchingData=false;
      });
    },
    // eslint-disable-next-line
    async downloadPDFById({ commit, dispatch }, data) {
      console.log("Data?",data);

      if (data.user_signature_required && !data.user_signed_ts) {
        dispatch('alerts/Notify', { 'txtMsg': 'Pending Dr. signature', 'txtType': 'negative' }, { root: true });
        return false;
      }

      apiCall.get(hostForAPICalls + `/api/v1/consent-requests/${data.id}/download2`, {
          responseType: 'blob',
          headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
      }).then(response => {
          // Check if the response is a Blob or JSON (error case)
          const contentType = response.headers['content-type'];
  
          if (contentType && contentType.includes('application/json')) {
              // Convert Blob to JSON in case of error response
              const reader = new FileReader();
              reader.onload = function() {
                  const errorResponse = JSON.parse(reader.result);
  
                  // Handle the error message
                  if (errorResponse?.success === false) {
                      console.error("Download failed:", errorResponse);
                      dispatch('alerts/Notify', { 'txtMsg': errorResponse.message, 'txtType': 'negative' }, { root: true });
                  }
              };
              reader.readAsText(response.data);
          } else {
              // Successful download, handle Blob as PDF
              const blob = new Blob([response.data], { type: contentType });
              const url = window.URL.createObjectURL(blob);
  
              const link = document.createElement('a');
              link.href = url;
              let fileName = data?.pdf 
                ? data.pdf.replace('app/pdf/', '') 
                : data.pdf_consent;

              if (!fileName) {
                const currentDate = new Date();
                const formattedDate = currentDate.toISOString().replace('T', ' ').split('.')[0];
                fileName = `Patient_Info_${data?.patient?.first_name || 'Unknown'} ${data?.patient?.last_name || 'Unknown'} ${formattedDate}.pdf`;
              }

              link.download = fileName.replace(/ /g, '_'); // Set the filename
              link.click();
  
              // Cleanup the temporary URL
              window.URL.revokeObjectURL(url);
          }
      })
      .catch(error => {
          console.error("Error fetching consent PDF:", error);
          dispatch('alerts/Notify', { 'txtMsg': 'Error fetching PDF', 'txtType': 'negative' }, { root: true });
      })
      .finally(() => {
          state.bFetchingData = false; // Ensure fetching state is updated
      });
    },
  }
  
  const mutations = {
    setSig(state,sig) {state.CRSig=sig},
    setConsentRequest(state,consentRequest) { state.consentRequest=consentRequest},
    setConsentTypes(state,rTypes) {state.consentTypes=rTypes},
    setConsentPDF(state,consentPDF) { state.consentPDF=consentPDF}
  };
  
  export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
  };